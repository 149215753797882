import React, {Fragment} from 'react';
import styles from './Post.module.scss';
import { Link } from 'gatsby';
import LeaveMessageBanner from '../LeaveMessageBanner';
import {BLOG_LEAVE_MESSAGE_HEADING, BLOG_LEAVE_MESSAGE_SUBHEADING} from '../../constants/leave-message';

const Post = ({ post }) => {
  const { html } = post;
  const { slug, tagSlugs } = post.fields;
  const { tags, title, date } = post.frontmatter;
  const dateObj = new Date(date);
  const displayBanner = slug === '/posts/brief-introduction-to-machine-translation';

  return (
    <Fragment>
      <div className={styles['post']}>
        <div className={styles['post__content']}>
          <h1 className={styles['post__content__title']}>{title}</h1>
          <div className={styles['post__content__meta']}>
            <div className={styles['post__content__meta__date']}>
              {`${dateObj.toLocaleString('default', {
                month: 'short',
              })} ${dateObj.getDate()}`}
            </div>

            <div className={styles['post__content__meta__tags']}>
              {tagSlugs &&
              tagSlugs.map((slug, i) => (
                <Link
                  to={`/blog${slug}`}
                  key={slug}
                  className={styles['post__content__meta__tags']}
                >
                  #{tags[i]}
                </Link>
              ))}
            </div>
          </div>

          <div
            className={styles['post__content__body']}
            dangerouslySetInnerHTML={{ __html: html }}
          />
        </div>
      </div>
      {
        displayBanner && <LeaveMessageBanner
          className={styles['post-banner']}
          heading={BLOG_LEAVE_MESSAGE_HEADING}
          headingClassName={styles['post-banner-heading']}
          subHeading={BLOG_LEAVE_MESSAGE_SUBHEADING}
          subHeadingClassName={styles['post-banner-subheading']}
          subHeadingMobile={BLOG_LEAVE_MESSAGE_SUBHEADING}
        />
      }
    </Fragment>
  );
};

export default Post;
